<template>
    <div>
        <!--<nl-loader v-if="preloading" :show="true"></nl-loader>-->

        <div class="box">
            <NlTabs :tabs="tabs">
                <template #default="props">
                    <UrlTable v-if="props.openOnce[props.tab.index]" type="all" :status="props.tab.type" :scrap-pending="false" :price-update-pending="false" :display-serp-buttons="false" />
                </template>
            </NlTabs>
        </div>
    </div>
</template>

<script>
    import NlTabs from '@/components/UI/Tabs/NlTabs.vue'
    import UrlTable from '@/components/Admin/Sites/Urls/UrlTable.vue'
    import { mapActions } from 'vuex'

    export default {
        name: 'AdminUrls',
        components: { NlTabs, UrlTable },
        data() {
            return {
                preloading: true,
                counts: {
                    total: 0,
                    saleable: 0,
                    not_saleable: 0,
                    not_scrapped: 0
                }
            }
        },
        computed: {
            tabs() {
                return [
                    {
                        type: 'all',
                        label: `Tous (${this.counts.total.toLocaleString('fr-FR')})`,
                        icon: 'list',
                        disabled: this.counts.total === 0,
                        index: 0
                    },
                    {
                        type: 'saleable',
                        label: `Vendables (${this.counts.saleable.toLocaleString('fr-FR')})`,
                        icon: 'check',
                        disabled: this.counts.saleable === 0,
                        index: 1,
                        status: true
                    },
                    {
                        type: 'not_saleable',
                        label: `Invendables (${this.counts.not_saleable.toLocaleString('fr-FR')})`,
                        icon: 'times',
                        disabled: this.counts.not_saleable === 0,
                        index: 2
                    },
                    {
                        type: 'not_scrapped',
                        label: `Non scrappées (${this.counts.not_scrapped.toLocaleString('fr-FR')})`,
                        icon: 'clock',
                        disabled: this.counts.not_scrapped === 0,
                        index: 3
                    }
                ]
            }
        },
        created() {
            this.getStatusCounts().then(response => {
                this.counts = response
                this.preloading = false
            })
        },
        methods: {
            ...mapActions('urls', ['getStatusCounts'])
        }
    }
</script>
